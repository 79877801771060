<template>
  <base-component 
    :animal-uid="animal" 
    :date="date"
  >
    <template slot="title">
      <h3>Résultat d'analyse : {{ selectedObject|libelle }}</h3>
    </template>
    <template slot="content">
      <div class="reference-valeur">
        <div class="row">
          <table
            class="table table-hover table-bordered" 
            v-if="isSectionTab"
          >
            <thead>
              <tr>
                <th>Paramètre</th>
                <th>Valeur</th>
                <th
                  v-for="(e,i) in especes"
                  :key="i"
                >
                  Reférence {{ e.libelle }} [{{ e.code }}]
                </th>
              </tr>
            </thead>
            <tbody
              v-for="(s,index) in filtredSections"
              :key="index"
            >
              <tr>
                <td
                  colspan="100%"
                  style="background-color:black; color: white; text-align: center; font-weight: bold; font-size: 14px;"
                >
                  {{ s|libelle }}
                </td>
              </tr>
              <tr
                v-for="(p, i) in filtredParametres(s)"
                :key="i"
              >
                <td>{{ p|libelle }}</td>
                <td />
                <td
                  v-for="(e, k) in especes"
                  :key="k"
                >
                  {{ valeurRef(p, e) }}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            class="table table-hover table-bordered" 
            v-if="!isSectionTab"
          >
            <thead>
              <tr>
                <th>Paramètre</th>
                <th>Valeur</th>
                <th
                  v-for="(e,i) in especes"
                  :key="i"
                >
                  Reférence {{ e |libelle }} [{{ e.code }}]
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(p, i) in filtredParametersByFiche"
                :key="i"
              >
                <td>{{ p.libelle }}</td>
                <td />
                <td
                  v-for="(e, k) in especes"
                  :key="k"
                >
                  {{ valeurRef(p, e) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="text-align: center; padding-bottom: 100px;">
                  Commentaire: 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </base-component>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import baseComponent from '../../../../sanitaire/analyse/fiche/base.vue'
export default {
  components: {baseComponent},
    data(){
        return {
        }
    },
    methods: {
        valeurRef(parametre, espece){
            let r = [...this.references].find(item => item.espece === espece.uid && item.parametre === parametre.uid)
            if(r) return r.valeur
            return '-'
        },
        filtredParametres(section){
            if(this.selectedObject !== null )
                return [...this.parametres].filter(item => item.fiche.split(',').includes(this.selectedObject.uid) && item.section === section.uid)
            return []
        }
    },
    computed: {
        ...mapGetters({
            selectedObject: 'selectedObject',
            especes: 'identification/activeEspeces',
            sections : 'sanitaire/ficheSections',
            parametres: 'sanitaire/ficheParametres',
            references: 'sanitaire/ficheReferences',
            animaux: 'identification/animaux'
        }),
        animal(){ return this.animaux[0].uid},
        date(){ return new moment().format('lll')},
        filtredSections(){
            if(this.selectedObject !== null )
                return [...this.sections].filter( item => item.fiche === this.selectedObject.uid)
            return []
        },
        filtredParametersByFiche(){
          if(this.selectedObject !== null ){
            return [...this.parametres].filter(item =>  item.fiche.split(',').includes(this.selectedObject.uid))
          }
          return []
        },
        isSectionTab(){ return this.filtredSections.length > 0 }
    }

}
</script>

<style lang="scss" scoped>
.reference-valeur{
  padding: 20px;
}
</style>