<template>
  <base-component
    :animal-uid="animal"
    :date="date"
  >
    <template slot="content">
      <div class="resultat-direct">
        <div class="row examen">
          <div class="col-3">
            <h6>Examen demandé:</h6>
          </div>
          <div class="col-9" />
        </div>
        <div class="row resultat">
          <div class="col-3">
            <h6>Résultat:</h6>
          </div>
          <div class="col-9" />
        </div>
      </div>
    </template>
  </base-component>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import baseComponent from '../../../../sanitaire/analyse/fiche/base.vue'
export default {
    components: {baseComponent},
    data(){
        return {
        }
    },
    methods: {
        
    },
    computed: {
        ...mapGetters({
            selectedObject: 'selectedObject',
            animaux: 'identification/animaux'
        }),
        animal(){ return this.animaux[0].uid},
        date(){ return new moment().format('lll')}
        
    }

}
</script>

<style lang="scss" scoped>
.resultat-direct{
  margin: 0px;
  padding-top: 20px;
  border: solid 1px rgba(0,0,0,0.17);
  .examen, .resultat{
  padding: 20px;
  margin-bottom: 30px;
  h6{
    padding-bottom: 5px;
    text-decoration: underline;
  }
}

}

</style>