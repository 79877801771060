<template>
  <base-component
    :animal-uid="animal"
    :date="date"
  >
    <template slot="content">
      <div class="row">
        <div class="col-8">
          <table class="table table-bordered table-hover table-striped">
            <thead>
              <th>Libelle</th>
              <th>Localisation des lésions</th>
              <th>Caracterisation</th>
            </thead>
            <tbody>
              <tr
                v-for="(p, i) in filtredParametersByFiche"
                :key="i"
              >
                <td style="width: 30%;">
                  {{ p|libelle }}
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <h4>Synthèse de la description</h4>
              <div class="valeur" />
            </div>
            <div class="col-12">
              <h4>Interpretation</h4>
              <div class="valeur" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-component>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import baseComponent from '../../../../sanitaire/analyse/fiche/base.vue'
export default {
    components: {baseComponent},
    data(){
        return {
        }
    },
    methods: {
        
    },
    computed: {
        ...mapGetters({
            selectedObject: 'selectedObject',
            animaux: 'identification/animaux',
            parametres: 'sanitaire/ficheParametres',
        }),
        animal(){ return this.animaux[0].uid},
        date(){ return new moment().format('lll')},
        filtredParametersByFiche(){
          if(this.selectedObject !== null ){
            return [...this.parametres]
                    .filter(item =>  item.fiche.split(',').includes(this.selectedObject.uid))
                    // .sort((a,b) => a.libelle.localeCompare(b.libelle))
          }
          return []
        },
        
    }

}
</script>

<style lang="scss" scoped>
.valeur{
  min-height: 200px;
}

</style>